<script>
    export let sectionColor = 'bg-gray-300';
    export let sectionName = '';
    export let sectionHeightAuto = false;
    export let sectionFullWidth = false;
    export let sectionContentPosition = 'items-center';
    export let sectionGSAPOverlap = false;
</script>
<a href="#o" tabindex="0" aria-label="{sectionName}" data-section="{sectionName}" class="js-access-section-link"></a>
<section
    id="{sectionName}"

    class="
        relative flex
        { sectionColor }
        { sectionGSAPOverlap ? 'gs-panel' : '' }
        {sectionHeightAuto ? 'section-wrapper--auto' : 'section-wrapper'}
        {sectionContentPosition}
">
    <slot name="background"></slot>
    <div class="{sectionFullWidth ? 'w-full' : 'container'} mx-auto relative">
        <slot></slot>
    </div>
</section>

<style lang="scss">
  .section-wrapper {

    min-height: calc(100vh);

    &__padding {
      padding-top: var(--section-padding-top);
      transition: padding-top 0.3s ease;
    }

    &--auto {
      min-height: initial;
    }
  }
</style>